<template>
  <div class="send-wrap">
    <van-nav-bar
      :title="$route.meta.title"
      left-text="返回"
      right-text="添加"
      left-arrow
      z-index="2"
      :fixed="true"
      :placeholder="true"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />

    <div class="content">
      <h3>定时短信待发送</h3>
      <div class="crontab">
        <div class="crontabLine" v-for="(item, index) in crontabList" :key="index">
          <div class="identify"></div>
          <div class="box">
            <div class="box-up">
              <h4>{{ item.group_name }}&nbsp;({{ item.group_member_num }})</h4>
              <van-button round type="info" @click="crontabCancel(item.id)">取消</van-button>
            </div>
            <div class="box-down">
              <span>{{ item.describe }}</span>
              <div>
                距发送还剩&nbsp;
                <i>{{ item.diff_day }}天</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3>节假日提醒</h3>

      <div class="holiday">
        <div class="holidayLine" v-for="(item, index) in holidayList" :key="index">
          <div class="identify" :style="{background:item.checked?'#04C79B':'#CDCDCD'}" ></div>
          <div class="box">
            <div class="left">
              <h4>{{ item.holiday_name }}</h4>
              <div>
                <span>{{ item.holiday_date+'&nbsp;'+item.holiday_week}}</span>
                <div>
                  还剩&nbsp;
                  <i>{{ item.diff_day }}天</i>
                </div>
              </div>
            </div>
            <div class="right">
              <van-button v-on:click="$router.push({name:'editHoliday',query:item})" v-if="item.checked" round>编辑</van-button>
              <van-switch @change="switchChange(item)" v-model="item.checked" active-color="#09C89D" inactive-color="#CCCCCC" size="22px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import table from '@/api/table';
export default {
  name: 'send',
  data() {
    return {
      crontabList: [],
      holidayList: []
    };
  },
  created() {
    this.load()
  },
  methods: {
    // 加载数据
    load() {
      this.getCrontabList().then(res => {});
      this.getHolidayList()
    },
    // 取消定时
    crontabCancel(id) {
      table.crontabDelete({ id }).then(response => {
        if (response.data.result) {
          this.getCrontabList();
          this.getHolidayList()
        }
      });
    },
    switchChange(item){
      if(item.checked){
        this.$router.push({name:'editHoliday',query:item})
      }else{
        this.crontabCancel(item.id)
      }
    },
    //定时任务-列表
    getCrontabList() {
      return new Promise(resolve => {
        table.crontabList().then(response => {
          this.crontabList = response.data.list;
          resolve(response)
        });
      });
    },
    // 节假日提醒
    getHolidayList() {
      table.holidayList().then(respnese => {
        this.holidayList = respnese.data.list.map(item=>{
          if(item.group_id==0){
            item.checked=false
          }else{
            item.checked=true
          }
          return item
        })
      });
    },
    // nav导航栏事件
    onClickLeft() {
      this.$router.push('index')
    },
    onClickRight() {
      this.$router.push('addsend')
    }
  }
};
</script>
<style lang="scss" scoped>
.send-wrap {
  .content {
    padding: 0 30px;
    h3 {
      margin: 54px 0 32px;
      font-size: 26px;
      font-weight: 500;
      color: #999999;
    }
    .crontabLine::before{
      position: absolute;
      content:'';
      width: 10px;
      height: 100%;
      background-color: #fe4600;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .crontabLine {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      background: #fff;
      border-radius: 10px;
      .box {
        flex: 1;
        padding: 40px 30px;
        .box-up {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          h4 {
            font-size: 32px;
            font-weight: bold;
            color: #333333;
          }
          button {
            padding: 0 16px;
            height: 42px;
            background: #2c6bf8;
            opacity: 0.98;
            border-radius: 21px;
            font-size: 24px;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .box-down {
          display: flex;
          justify-content: space-between;
          span {
            flex: 2;
            font-size: 24px;
            font-weight: 500;
            color: #999999;
          }
          div {
            text-align: right;
            flex: 1;
            font-size: 24px;
            font-weight: 500;
            color: #000000;
            i {
              color: #fe5900;
            }
          }
        }
      }
    }
    .holidayLine {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      background: #fff;
      border-radius: 10px;
      .identify {
        width: 10px;
        height: 150px;
        background-color: #04c79b;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .box {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        .left {
          h4 {
            margin-bottom: 5px;
            font-size: 32px;
            font-weight: bold;
            color: #333333;
          }
          > div {
            display: flex;
            font-size: 24px;
            font-weight: 500;
            color: #6e7370;
            div {
              padding-left: 15px;
              color: #333;
              i {
                color: #fe5900;
              }
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          button {
            margin-right: 16px;
            padding: 0 16px;
            height: 42px;
            background: #ffffff;
            border: 1px solid #c9c9c9;
            opacity: 0.98;
            font-size: 24px;
            font-weight: 500;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
